<template>
  <div>
    <div class="row mt-3">
      <div class="col-md-2">
        <div class="">
          <b-form-group
              class="mb-0">
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
      <div class="col-md-2">

        <download-excel
            :data="items"
            class="btn btn-primary"
            :fields="json_fields"
            worksheet="Agent wise doctor list"
            name="Agent wise doctor list">
          <i class="fas fa-file-excel"></i> Export Excel
        </download-excel>

      </div>
      <div class="col-md-8">
        <b-form-group
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"/>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
            </b-input-group-append>

          </b-input-group>

        </b-form-group>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <b-table
            striped hover responsive
            :items="filtered"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :filter-included-fields="filterOn"
            thead-class="bg-primary text-white"
            class="mt-10"
            show-empty>

          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
            </td>
          </template>

          <template #cell(connections)="row">
            <div v-if="row.item.connections">
              <b-badge variant="success" v-if="row.item.connections ? row.item.connections.is_accepted == '1': '0'"
                       class="font-size-10">Connected
              </b-badge>
              <b-badge variant="danger" v-else class="font-size-10"> Not connected</b-badge>
            </div>
          </template>

          <template #cell(service_fee)="row">
            <div v-if="row.item.doctor_information">
              <p v-for="(service, index) in row.item.doctor_information.service_fee"
                 :key="index"
                 class="font-size-10 mb-0">
                <span v-if="index == 'call_fee' || index=='visit_fee'">
                    {{ underScoreToSpace(index == 'call_fee' ? 'Virtual fee' : 'Visit fee') }} - {{ service }}
                </span>
              </p>
            </div>
          </template>

          <template #cell(age)="row">
            <div v-if="row.item.age">
              <div v-if="row.item.age">
                <p>
                  {{ row.item.age }}
                </p>
              </div>
              <div v-else>
                <b-badge variant="danger" class="font-size-10"> Data not found</b-badge>
              </div>
            </div>
          </template>

          <template #cell(image)="row">
            <div class="is_online" v-if="row.item.image">
              <img :src="userPhoto(row.item.image)" alt="image"
                   class="user_image" v-if="row.item.image"/>
              <img src="../../../assets/img/blank.png" alt="" class="user_image" v-else>
              <i :class="`${row.item.is_online == '1' ? 'text-green ' : 'text-danger'} fas fa-circle is_online_icon`"></i>
            </div>
          </template>

          <template #cell(action)="row">
            <div v-if="row.item.connections">
              <div v-if="row.item.connections.is_block==0">
                <button class="btn btn-danger" @click="disconnect(row.item)"><i class="fas fa-ban"></i></button>
              </div>
              <div v-else>
                <button class="btn btn-success" @click="connect(row.item)"><i class="far fa-check-circle"></i></button>
              </div>
            </div>
          </template>

        </b-table>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <b-pagination
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
            size="lg"
            class="my-0"
        ></b-pagination>
      </div>
    </div>
    <!--    Disconnect modal -->
    <b-modal
        id="disconnectModal"
        title="Please provide a reason"
        @show="resetModal"
        @hidden="resetModal"
        size="lg"
        @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">

        <b-form-group
            label="Comment"
            label-for="name-input"
            invalid-feedback="Comment required"
            :state="commentState">

          <b-form-textarea
              id="textarea"
              v-model="form.comment"
              placeholder="Enter something..."
              rows="3"
              :state="commentState"
              required
          ></b-form-textarea>
        </b-form-group>


      </form>
    </b-modal>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {underScoreToSpace} from "../../../healper/nameFormating";

export default {
  name: "DoctorList",
  data() {
    return {
      // user: '',
      // Download options
      downloadLoading: true,
      filename: '',
      autoWidth: true,
      bookType: 'xlsx',
      json_fields: {
        "Fullname": {
          field: "fullname",
        },
        "Slug": {
          field: "slug",
        },
        "Phone number": {
          field: "phone_number",
        },
        "Age": {
          field: "age",
        },
        "Wallet": {
          field: "wallet",
        },
      },
      columns: [
        {
          key: 'id',
          label: 'Id',
        },
        {
          key: 'fullname',
          label: 'Fullname',
        },
        {
          key: 'slug',
          label: 'Slug',
        },
        {
          key: 'phone_number',
          label: 'Phone number',
        },
        {
          key: 'age',
          label: 'Age',
        },
        {
          key: 'wallet',
          label: 'Wallet',
        },
        {
          key: 'action',
          label: 'Action'
        }
      ],
      loading: false,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 20, 40, 60, {value: 100, text: "Show a lot"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',

      localActive: false,
      items: [],
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'image',
          label: 'Image',
          sortable: true
        },
        {
          key: 'fullname',
          label: 'Fullname',
          sortable: true
        },
        {
          key: 'slug',
          label: 'Slug',
          sortable: true
        },
        {
          key: 'connections',
          label: 'Connections status',
          sortable: true
        },
        {
          key: 'nature_of_service',
          label: 'Nature of service',
          sortable: true
        },
        {
          key: 'service_fee',
          label: 'Fees',
          sortable: true
        },
        {
          key: 'phone_number',
          label: 'Phone number',
          sortable: true
        },
        {
          key: 'age',
          label: 'Age',
          sortable: true,
        },
        {
          key: 'wallet',
          label: 'Wallet',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Actions',
          sortable: true,
        }
      ],
      filter: null,
      filterOn: [],
      filters: {
        id: '',
        fullname: '',
        phone_number: '',
        age: '',
        wallet: '',
        slug: '',
        service_fee: '',
        nature_of_service: '',
        connections: '',
      },
      startDate: null,
      endDate: null,
      show: true,
      base_url: process.env.VUE_APP_API_URL,
      users: [],
      commentState: null,
      form: {
        comment: '',
      },
      doctor_id: '',
      connection_status: '',
      action_type: '',

    }
  },
  metaInfo: {
    title: 'Orko Health Ltd | Agent wise doctor list',
    htmlAttrs: {
      lang: 'en-US'
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Agent wise doctor lists", route: "/agent-wise-doctor-list"},
    ]);
  },
  created() {
    this.getDoctorList();
  },
  computed: {
    rows() {
      return this.users.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    },
    filtered() {

      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const filtered = this.items.filter(item => {
        const itemDate = new Date(item.registration_date)
        if (startDate && endDate) {
          return startDate <= itemDate && itemDate <= endDate;
        }
        if (startDate && !endDate) {
          return startDate <= itemDate;
        }
        if (!startDate && endDate) {
          return itemDate <= endDate;
        }
        return Object.keys(this.filters).every(key =>
            String(item[key]).includes(this.filters[key]))
      })


      return filtered.length > 0 ? filtered : [{
        id: '',
        fullname: '',
        phone_number: '',
        age: '',
        wallet: '',
        slug: '',
        service_fee: '',
        nature_of_service: '',
        connections: '',
      }]
    },
    filteredData() {

      let filterType = this.selectedType;
      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
      return itemsByType
          .filter(item => {
            const itemDate = new Date(item.registration_date)
            if (startDate && endDate) {
              return startDate <= itemDate && itemDate <= endDate;
            }
            if (startDate && !endDate) {
              return startDate <= itemDate;
            }
            if (!startDate && endDate) {
              return itemDate <= endDate;
            }
            return true;
          })
    }
  },
  methods: {

    getDoctorList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/partner/agent/doctor/list/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.items = response.data.data
            this.users = response.data.data
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return '../../../public/media/users/blank.png'
      }
    },
    localizeDate(date) {
      if (!date || !date.includes('-')) return date
      const [yyyy, mm, dd] = date.split('-')
      return new Date(`${mm}/${dd}/${yyyy}`)
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
    },
    underScoreToSpace,

    disconnect(item) {
      this.doctor_id = item.id
      this.$bvModal.show('disconnectModal')
      this.connection_status = 0;
      this.action_type = 'de-active';

    },
    connect(item) {
      this.doctor_id = item.id
      this.$bvModal.show('disconnectModal')
      this.connection_status = 1;
      this.action_type = 'active';

    },
    sendInfo(item) {
      this.doctor_id = item.category_id
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.commentState = valid
      return valid
    },
    resetModal() {
      this.form = {};
      this.commentState = null
      this.connection_status = '';
      this.action_type = '';
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      let form = new FormData();

      form.append("connected_to", this.$route.params.id);
      form.append("requested_to", this.doctor_id);
      form.append("status", this.connection_status);
      form.append("action_type", this.action_type);
      form.append("module_type", "connection-request");
      form.append("comment", this.form.comment);

      // Hide the modal manually
      this.$nextTick(() => {
        axios
            .post(`${process.env.VUE_APP_URL}/api/v1/partner/agent/connection/status/manipulate?api_token=${localStorage.getItem('api_token')}`, form)
            .then(response => {
              if (response.data.status_code === 400) {
                this.$snotify.error(response.data.message);

                // setTimeout(() => {
                this.$router.push('/agent-wise-doctors-list/' + this.$route.params.id);
                // }, 1000);

              } else {
                this.$bvModal.hide('disconnectModal');
                this.$snotify.success('Successfully connected');

                this.getDoctorList();

              }

            })
      })
    },
  }
}
</script>
<style>
.hide {
  display: none;
}

.text-green {
  color: green;
}

.is_online {
  position: relative;
}

.is_online_icon {
  position: absolute;
  left: 0;
  bottom: 4px;
  margin-left: 14px;
  font-size: 12px;
}

.user_image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  text-align: center;
}

.font-size-10 {
  font-size: 10px;
}
</style>